import { request, get, post, patch, del } from "./request.js";

let api = {
  getRefreshDevice: "portal/rest/1.0/device/control/refresh", // 刷新设备数据
  getDeviceWriteReg: "portal/rest/1.0/device/control/writeReg", // 修改设置参数
  getDeviceDevs: "portal/rest/1.0/device/devs", // 设备参数
  // deviceGroup: "portal/rest/1.0/device/group", //获取分组
  deviceGroup: "portal/rest/1.0/device/group/listGroup", //获取分组 《新接口》
  getdeviceByGroup: "portal/rest/1.0/device", //获取设备
  getresourceByDevice: "portal/rest/1.0/device/regs", //获取资源
  getWarnHistroy: "portal/rest/1.0/device/history/warn", //获取报警历史
  getControlHistroy: "portal/rest/1.0/device/history/control", //获取操作历史
  controlAll: "portal/rest/1.0/device/control/ctrlAll", //设备全开&全关
  getTask: "portal/rest/1.0/device/task", //获取定时任务
  deleteBind: "portal/rest/1.0/device/bind", //删除绑定
  deleteBeBind: "portal/rest/1.0/device/bind", //删除被绑定
  deleteTask: "portal/rest/1.0/device/task", //删除任务
  insertBind: "portal/rest/1.0/device/bind", //绑定
  getBind: "portal/rest/1.0/device/bind", //获取逻辑任务
  insertTask: "portal/rest/1.0/device/task", //添加定时任务
  updateTask: "portal/rest/1.0/device/task", //更新定时任务
  clearBind: "portal/rest/1.0/device/bind/clear", //清除任务
  getResourceWarn: "portal/rest/1.0/device/reg/warn", //获取资源报警设置
  updateWarn: "portal/rest/1.0/device/reg/warn", //修改资源报警设置
  updateResourceInfo: "portal/rest/1.0/device/reg", //修改资源信息
  getResourceConfig: "portal/rest/1.0/device/reg/conf", //获取资源参数
  updateResourceConfig: "portal/rest/1.0/device/control/writeReg", //修改资源参数
  exitGroup: "portal/rest/1.0/device/group", //添加分组
  insertBindToGroup: "portal/rest/1.0/device/activate", // 绑定设备到分组
  updateGrouptoGroup: "portal/rest/1.0/device/patchGroup", //修改设备所在分组
  unBindDevice: "portal/rest/1.0/device/unbind/", //设备解绑
  updateDevice: "portal/rest/1.0/device", //编辑设备信息,
  sortResource: "portal/rest/1.0/device/reg/sort", //资源排序
  sortGroup: "portal/rest/1.0/device/group/sort", //分组排序
  sortDevice: "portal/rest/1.0/device/sort", //设备排序
  getResourceHistory: "portal/rest/1.0/device/history/reg", //获取资源历史记录
  getDeviceDetail: "portal/rest/1.0/device/", //设备详情
  deviceShare: "portal/rest/1.0/device/shareMutil", //分享多个设备
  groupShare: "portal/rest/1.0/device/group/share/", //分享分组
  getViceUserSlave: "portal/rest/1.0/device/slave", //副用户列表
  getMainUserAuthority: "portal/rest/1.0/device/self/authority", //主用户权限明细
  updateMainUserAuthority: "portal/rest/1.0/device/main/authority", //主用户权限修改
  getViceUserAuthority: "portal/rest/1.0/device/slave/authority", //副用户权限明细
  updateViceUserAuthority: "portal/rest/1.0/device/slave/authority", //修改副用户权限
  unBindViceUser: "portal/rest/1.0/device/unbind/slave", //解绑副用户
  updateRegShow: "portal/rest/1.0/device/reg/show", //显示隐藏资源
  getWarnMoreConfig: "portal/rest/1.0/device/reg/getWarnMore", //获取报警规则设置
  updateWarnMoreConfig: "portal/rest/1.0/device/reg/warnMore", //修改报警参数设置
};

// 刷新设备数据
function getDeviceDetail(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getDeviceDetail + params, "", configs);
}

// 刷新设备数据
function getRefreshDevice(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getRefreshDevice, params, configs);
}

// 修改设备配置参数
function getDeviceWriteReg(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.getDeviceWriteReg, params, configs);
}

// 获取设备参数
function getDeviceDevs(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getDeviceDevs, params, configs);
}

// 获取资源历史记录
function getResourceHistory(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getResourceHistory, params, configs);
}

// 获取设备分组
function getdeviceGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.deviceGroup, params, configs);
}

// 获取设备设备
function getdeviceByGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getdeviceByGroup, params, configs);
}

// 获取设备资源
function getresourceByDevice(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getresourceByDevice, params, configs);
}

// 获取报警资源
function getWarnHistroy(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getWarnHistroy, params, configs);
}

// 获取资源参数
function getResourceConfig(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getResourceConfig, params, configs);
}

// 修改资源配置参数
function updateResourceConfig(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.updateResourceConfig, params, configs);
}

// 获取操作历史
function getControlHistroy(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getControlHistroy, params, configs);
}

// 设备全开 全关
function controlAll(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.controlAll, params, configs);
}

// 添加分组
function insertGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.exitGroup, params, configs);
}

// 资源排序
function sortResource(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.sortResource, params, configs);
}

// 分组排序
function sortGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.sortGroup, params, configs);
}

// 设备排序
function sortDevice(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.sortDevice, params, configs);
}

// 更新分组信息
function updateGroupInfo(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.exitGroup, params, configs);
}

// 更新分组
function updateGrouptoGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateGrouptoGroup, params, configs);
}

// 删除分组
function removeGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return del(api.exitGroup, params, configs);
}

//绑定设备到分组
function insertBindToGroup(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.insertBindToGroup, params, configs);
}

// 更新设备信息
function updateDevice(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateDevice, params, configs);
}

// 通过设备id获取设备操作 (task 任务)
function getTask(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getTask, params, configs);
}

// 通过设备id获取逻辑任务(bind 绑定)
function getBind(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getBind, params, configs);
}

// 通过设备id 资源id 获取报警设置
function getResourceWarn(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(api.getResourceWarn, params, configs);
}

// 更新资源报警设置
function updateWarn(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateWarn, params, configs);
}

// 添加定时任务 task
function insertTask(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.insertTask, params, configs);
}

// 更新定时任务 task
function updateTask(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateTask, params, configs);
}

// 修改资源信息
function updateResourceInfo(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateResourceInfo, params, configs);
}

// 删除绑定
function deleteBind(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json",
    },
  };
  return del(api.deleteBind, params, configs);
}

// 删除绑定
function unBindDevice(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json",
    },
  };
  return del(api.unBindDevice + params, "", configs);
}

// 删除被绑定
function deleteBeBind(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json",
    },
  };
  return del(api.deleteBeBind, params, configs);
}

// 删除任务
function deleteTask(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json, text/plain",
    },
  };
  return del(api.deleteTask, params, configs);
}

// 绑定设备
function insertBind(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.insertBind, params, configs);
}

// 清空绑定
function clearBind(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return del(api.clearBind, params, configs);
}

// 分享分组
function groupShare(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
    },
  };
  return get(`${api.groupShare}${params}`, params, configs);
}

// 分享多个设备
function deviceShare(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.deviceShare, params, configs);
}

// 副用户列表
function getViceUserSlave(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return get(api.getViceUserSlave, params, configs);
}

// 主用户权限明细
function getMainUserAuthority(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return get(api.getMainUserAuthority, params, configs);
}

// 主用户权限修改
function updateMainUserAuthority(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateMainUserAuthority, params, configs);
}

// 副用户权限明细
function getViceUserAuthority(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return get(api.getViceUserAuthority, params, configs);
}

// 修改副用户权限
function updateViceUserAuthority(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateViceUserAuthority, params, configs);
}

// 解绑副用户
function unBindViceUser(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return del(api.unBindViceUser + `/${params.id}`, params, configs);
}

// 显示隐藏资源
function updateRegShow(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return patch(api.updateRegShow, params, configs);
}

// 获取报警规则设置
function getWarnMoreConfig(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return get(api.getWarnMoreConfig, params, configs);
}

// 修改报警参数设置
function updateWarnMoreConfig(params) {
  var configs = {
    headers: {
      token: window.localStorage.getItem("token"),
      "content-type": "application/json;charset=UTF-8",
    },
  };
  return post(api.updateWarnMoreConfig, params, configs);
}

export default {
  groupShare,
  deviceShare,
  getDeviceDetail,
  getdeviceGroup,
  getdeviceByGroup,
  getresourceByDevice,
  getControlHistroy,
  getWarnHistroy,
  controlAll,
  insertTask,
  updateTask,
  getTask,
  getBind,
  deleteBind,
  deleteBeBind,
  insertBind,
  clearBind,
  deleteTask,
  getResourceWarn,
  updateWarn,
  updateResourceInfo,
  getResourceConfig,
  updateResourceConfig,
  insertGroup,
  updateGrouptoGroup,
  updateGroupInfo,
  removeGroup,
  insertBindToGroup,
  unBindDevice,
  updateDevice,
  sortResource,
  sortGroup,
  sortDevice,
  getResourceHistory,
  getDeviceDevs,
  getDeviceWriteReg,
  getRefreshDevice,
  getViceUserSlave,
  getMainUserAuthority,
  updateMainUserAuthority,
  getViceUserAuthority,
  updateViceUserAuthority,
  unBindViceUser,
  updateRegShow,
  getWarnMoreConfig,
  updateWarnMoreConfig,
};
