<template>
  <div>
    <Breadcrumb :first="bread[0]" :second="bread[1]"></Breadcrumb>
    <el-row>
      <el-col>
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <h5>分组</h5>
            </el-col>
            <el-col
              v-loading="groupLoading"
              element-loading-text="加载中..."
              class="content_margin_top"
            >
              <div
                style="display: flex;justify-content:flex-start;flex-wrap: wrap;align-items: flex-start;min-height: 100px;"
              >
                <el-button
                  plain
                  type="primary"
                  :class="
                    activeBtn === index ? 'btn-group btn-active' : 'btn-group'
                  "
                  v-for="(item, index) in groupList"
                  size="mini"
                  @click="getdeviceByGroup(item.id, index)"
                >
                  {{ item.name }}
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="13">
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <h5>设备</h5>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                ref="tableDevice"
                :data="$store.state.tableDataDevice"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                @row-click="rowClick"
                :height="tableHeight"
              >
                <el-table-column prop="id" label="设备id" width="70px">
                </el-table-column>
                <el-table-column prop="name" label="设备名称">
                </el-table-column>
                <el-table-column label="设备状态" width="90px">
                  <template slot-scope="scope">
                    <el-tag
                      type="success"
                      size="mini"
                      v-if="scope.row.online == true"
                      >在线</el-tag
                    >
                    <el-tag
                      type="danger"
                      size="mini"
                      v-if="scope.row.online == false"
                      >离线</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column label="开关" width="150px">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      plain
                      @click.native.stop="controlAll(scope.row, true)"
                      :disabled="!scope.row.permissions.run"
                      >全开</el-button
                    >
                    <el-button
                      size="mini"
                      plain
                      @click.native.stop="controlAll(scope.row, false)"
                      :disabled="!scope.row.permissions.run"
                      >全关</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="刷新" width="70px">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      icon="el-icon-refresh"
                      :disabled="!scope.row.online"
                      @click.native.stop="getRefreshDevice(scope.row.id)"
                    >
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="280px">
                  <template slot-scope="scope">
                    <el-button
                      type="info"
                      size="mini"
                      @click.native.stop="getdeviceHistroy(scope.row)"
                      :disabled="!scope.row.permissions.run"
                      >历史
                    </el-button>
                    <el-button
                      size="mini"
                      @click.native.stop="getTask(scope.row.id)"
                      :disabled="
                        scope.row.online === false &&
                          scope.row.permissions.dev === false
                      "
                      >操作
                    </el-button>
                    <!-- <el-button
                      type="warning"
                      size="mini"
                      @click.native.stop="handlePara(scope.row)"
                      :disabled="
                        scope.row.online === false &&
                          scope.row.permissions.dev === false
                      "
                      >参数
                    </el-button> -->
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="11">
        <el-card class="shadow-sm">
          <el-row>
            <el-col>
              <h5>资源</h5>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                ref="tableResource"
                :data="$store.state.tableDataResource"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                :height="tableHeight"
              >
                <el-table-column
                  prop="addr"
                  label="地址"
                  width="100px"
                ></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column label="开关" width="120px">
                  <template slot-scope="scope">
                    <el-switch
                      v-if="scope.row.type == 'Bool'"
                      :disabled="!(deviceOnline && scope.row.rw)"
                      v-model="scope.row.value"
                      :active-value="true"
                      :inactive-value="false"
                      active-color="#13ce66"
                      @change="updateResourceStatus(scope.row)"
                      @click.stop.native
                    ></el-switch>
                    <el-select
                      style="width:100%"
                      size="small"
                      v-else-if="scope.row.type == 'Enum'"
                      v-model="scope.row.value"
                      placeholder="请选择"
                      :disabled="!(deviceOnline && scope.row.rw)"
                      @change="
                        open(
                          scope.row.addr,
                          scope.row.value,
                          scope.row.type,
                          scope.row.items
                        )
                      "
                    >
                      <el-option
                        v-for="item in scope.row.items"
                        :key="item.k"
                        :value="item.k"
                        :label="item.v"
                      ></el-option>
                    </el-select>
                    <el-button
                      v-else
                      type="primary"
                      plain
                      size="mini"
                      :disabled="!(deviceOnline && scope.row.rw)"
                      @click="changeValue($store.state.deviceid, scope.row)"
                    >
                      {{ scope.row | formatAnalog }}
                      {{ scope.row.unit }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="300px">
                  <template slot-scope="scope">
                    <el-button
                      type="info"
                      size="mini"
                      @click="getResourceHistory(scope.row)"
                      :disabled="
                        deviceOnline === false &&
                          $store.state.deviceRow.permissions.conf === false
                      "
                      v-loading.fullscreen.lock="historyLoading"
                      element-loading-text="数据加载中"
                    >
                      历史
                    </el-button>
                    <el-button
                      type="warning"
                      size="mini"
                      @click="getResourceConfig(scope.row)"
                      :disabled="
                        deviceOnline === false &&
                          $store.state.deviceRow.permissions.conf === false
                      "
                    >
                      参数
                    </el-button>
                    <el-button
                      type="danger"
                      size="mini"
                      @click="getResourceWarn(scope.row)"
                      :disabled="
                        deviceOnline === false &&
                          $store.state.deviceRow.permissions.conf === false
                      "
                    >
                      报警参数
                    </el-button>
                    <el-button
                      size="mini"
                      @click="getResourceInfo(scope.row)"
                      :disabled="
                        deviceOnline === false &&
                          $store.state.deviceRow.permissions.conf === false
                      "
                    >
                      编辑
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog
      title="设备历史记录"
      :visible.sync="dialogHistoryVisible"
      width="1000px"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="设备报警历史" name="first">
          <el-row>
            <el-col>
              <el-date-picker
                v-model="valueWarn"
                type="datetimerange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                size="small"
                @change="getWarnHistroy()"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                :data="warnTableData"
                size="mini"
                stripe
                border
                :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column
                  prop="deviceId"
                  label="设备id"
                  sortable
                  width="100px"
                >
                </el-table-column>
                <el-table-column prop="deviceName" label="设备名称" sortable>
                </el-table-column>
                <el-table-column prop="regAddr" label="地址" sortable>
                </el-table-column>
                <el-table-column prop="regName" label="地址名称" sortable>
                </el-table-column>
                <el-table-column prop="data" label="地址数据" sortable>
                </el-table-column>
                <el-table-column prop="online" label="是否报警" width="90px">
                  <template slot-scope="scope">
                    <el-tag
                      type="success"
                      size="mini"
                      v-if="scope.row.isWarn == false"
                      >报警解除
                    </el-tag>
                    <el-tag
                      type="danger"
                      size="mini"
                      v-if="scope.row.isWarn == true"
                      >报警触发</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="time" label="时间" sortable>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeWarn"
                @current-change="handleCurrentChangeWarn"
                :current-page="queryWarn.index"
                :page-sizes="[50, 100, 150, 200]"
                :page-size="queryWarn.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="queryWarn.total"
              >
              </el-pagination>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="设备操作历史" name="second">
          <el-row>
            <el-col>
              <el-date-picker
                v-model="valueControl"
                type="datetimerange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                size="small"
                @change="getControlHistroy()"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                :data="controlTableData"
                size="mini"
                stripe
                border
                :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column type="expand">
                  <template slot-scope="scope">
                    <div
                      style="display: flex;width: 100%;justify-content: center;padding: 20px 170px;"
                    >
                      <el-table
                        :data="scope.row.regActionList"
                        size="mini"
                        stripe
                        border
                        :header-cell-style="{
                          background: '#f9f9f9',
                          'text-align': 'center',
                        }"
                        :cell-style="{ 'text-align': 'center' }"
                      >
                        <el-table-column
                          prop="addr"
                          label="资源地址"
                          width="160px"
                        >
                        </el-table-column>
                        <el-table-column prop="regName" label="资源名称">
                        </el-table-column>
                        <el-table-column
                          prop="type"
                          label="资源类型"
                          width="120px"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="value"
                          label="资源值"
                          width="120px"
                        >
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="username" label="用户名" width="160px">
                </el-table-column>
                <el-table-column
                  prop="deviceId"
                  label="设备id"
                  sortable
                  width="100px"
                >
                </el-table-column>
                <el-table-column prop="deviceName" label="设备名称">
                </el-table-column>
                <el-table-column
                  prop="action"
                  label="操作"
                  sortable
                  width="120px"
                >
                </el-table-column>
                <el-table-column
                  prop="result"
                  label="结果"
                  sortable
                  width="120px"
                >
                </el-table-column>
                <el-table-column prop="date" label="时间" sortable>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeControl"
                @current-change="handleCurrentChangeControl"
                :current-page="queryControl.index"
                :page-sizes="[50, 100, 150, 200]"
                :page-size="queryControl.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="queryControl.total"
              >
              </el-pagination>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogHistoryVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="资源历史记录"
      :visible.sync="dialogResourceHistoryVisible"
      width="1000px"
      @close="(valueResource = ''), (resourceHistoryCurrentPage = 1)"
    >
      <el-row>
        <el-col>
          <el-date-picker
            v-model="valueResource"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptionsShort"
            size="small"
            @change="getResourceHistory(resourceRow)"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <el-button
            type="primary"
            size="small"
            @click="exportExcel()"
            style="margin-left: 20px;"
            >导出当前数据
          </el-button>
          <el-button
            size="small"
            @click="filterResourceHistory()"
            style="margin-left: 20px;"
          >
            筛选
          </el-button>
        </el-col>
        <el-col class="content_margin_top">
          <div
            id="regmain"
            style="width: 100%; height: 400px; margin-top: 20px"
          ></div>
        </el-col>
        <el-col class="content_margin_top">
          <el-table
            :data="
              tableDataResourceHistory.slice(
                (resourceHistoryCurrentPage - 1) * resourceHistoryPageSize,
                resourceHistoryCurrentPage * resourceHistoryPageSize
              )
            "
            size="mini"
            stripe
            border
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
            height="450px"
            id="exportTab"
          >
            <el-table-column prop="time" label="时间"></el-table-column>
            <el-table-column
              align="center"
              label="数值"
              v-if="queryResource.isBool === false"
            >
              <template slot-scope="scope">
                {{ scope.row.data }}
              </template>
            </el-table-column>
            <el-table-column
              label="开关状态"
              v-if="queryResource.isBool === true"
            >
              <template slot-scope="scope">
                <el-tag
                  type="success"
                  size="medium"
                  v-if="scope.row.data === 'true'"
                >
                  开
                </el-tag>
                <el-tag
                  type="danger"
                  size="medium"
                  v-if="scope.row.data === 'false'"
                >
                  关
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :background="true"
            :hide-on-single-page="true"
            :current-page="resourceHistoryCurrentPage"
            :page-sizes="[100, 200, 300, 400, 500]"
            :page-size="resourceHistoryPageSize"
            :total="tableDataResourceHistory.length"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChangeResourceHistory"
            @current-change="handleCurrentChangeResourceHistory"
            style="margin-top: 20px; text-align: right"
          />
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResourceHistoryVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="设备操作"
      :visible.sync="dialogControlVisible"
      width="1100px"
    >
      <el-tabs v-model="activeName" @tab-click="getactiveName()">
        <el-tab-pane label="定时任务" name="first">
          <el-row>
            <el-col>
              <el-button
                type="primary"
                size="small"
                @click="dialogTaskVisible = true"
                >添加定时任务</el-button
              >
            </el-col>
            <el-col class="content_margin_top">
              <el-table
                :data="tableDataTask"
                size="mini"
                stripe
                border
                :header-cell-style="{
                  background: '#f1f1f1',
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column prop="name" label="资源名称">
                </el-table-column>
                <el-table-column label="开启时间" width="100px">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.startHour + ":" + scope.row.startMinute
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="结束时间" width="100px">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.stopHour + ":" + scope.row.stopMinute
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="是否开启" width="80px">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.enable"
                      @change="updateTaskStatus(scope.row)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="是否重复" width="400px">
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.week[0] !== 7 || scope.row.week.length !== 1
                      "
                    >
                      <el-tag
                        type="primary"
                        size="small"
                        v-for="item in scope.row.week"
                        style="margin-left: 10px;"
                      >
                        周{{ item | switchWeek }}
                      </el-tag>
                    </div>
                    <div v-else>
                      <el-tag type="primary" size="small">不重复</el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="160px">
                  <template slot-scope="scope">
                    <el-button
                      type="warning"
                      size="mini"
                      @click="getTaskRowInfo(scope.row)"
                      >修改
                    </el-button>
                    <el-button
                      type="danger"
                      size="mini"
                      @click="displayDeleteTaskDialog(scope.row)"
                      >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="逻辑功能" name="second">
          <el-row>
            <el-col :span="12">
              <el-button
                type="primary"
                size="small"
                @click="dialogBindVisible = true"
                >绑定</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="displayClearDialog()"
                >清空所有绑定</el-button
              >
            </el-col>
            <el-col :span="12" class="text-right">
              <el-select
                size="small"
                v-model="resourceid"
                @change="getBind(resourceid)"
              >
                <el-option
                  v-for="item in resourceList"
                  :label="item.name"
                  :value="item.addr"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col class="content_margin_top">
              <el-tabs tab-position="left" style="height: 200px;">
                <el-tab-pane label="绑定">
                  <el-table
                    :data="tableDataBind"
                    size="mini"
                    stripe
                    border
                    :header-cell-style="{
                      background: '#f1f1f1',
                      'text-align': 'center',
                    }"
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column type="expand">
                      <template slot-scope="scope">
                        <div
                          style="display: flex;justify-content: center;padding: 10px 200px;"
                        >
                          <el-table
                            :data="scope.row.remotes"
                            size="mini"
                            stripe
                            border
                            :header-cell-style="{
                              background: '#f1f1f1',
                              'text-align': 'center',
                            }"
                            :cell-style="{ 'text-align': 'center' }"
                            style="width: 500px;"
                          >
                            <el-table-column
                              prop="remoteDeviceName"
                              label="远程设备名称"
                            >
                            </el-table-column>
                            <el-table-column
                              prop="remoteRegName"
                              label="远程设备资源名称"
                            >
                            </el-table-column>
                            <el-table-column label="操作" width="80px">
                              <template slot-scope="scope">
                                <el-button
                                  type="danger"
                                  size="mini"
                                  @click="displayDeleteBindDialog(scope.row)"
                                  >删除
                                </el-button>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="regName"
                      label="本地设备资源名称"
                    ></el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="被绑定">
                  <el-table
                    :data="tableDataBeBind"
                    size="mini"
                    stripe
                    border
                    :header-cell-style="{
                      background: '#f1f1f1',
                      'text-align': 'center',
                    }"
                    :cell-style="{ 'text-align': 'center' }"
                  >
                    <el-table-column prop="regName" label="当前设备资源名称">
                    </el-table-column>
                    <el-table-column
                      prop="remoteRegName"
                      label="发送方设备资源名称"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="remoteDeviceName"
                      label="发送方设备id"
                    >
                    </el-table-column>
                    <el-table-column label="操作" width="80px">
                      <template slot-scope="scope">
                        <el-button
                          type="danger"
                          size="mini"
                          @click="displayDeleteBeBindDialog(scope.row)"
                          >删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogControlVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="添加定时任务"
      :visible.sync="dialogTaskVisible"
      width="400px"
    >
      <el-form size="small" label-width="100px" v-model="taskform">
        <el-form-item label="资源地址">
          <el-select
            placeholder="请选择资源"
            v-model="taskform.addr"
            @change="getResourceType('insert')"
            class="w-100"
          >
            <el-option
              v-for="item in resourceList.filter((item) => item.rw)"
              :label="item.name"
              :value="item.addr"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开启时间">
          <el-time-picker
            arrow-control
            v-model="taskform.startTime"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            placeholder="选择开启时间"
            value-format="HH:mm"
            format="HH:mm"
            @change="getTime('start')"
            class="w-100"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="关闭时间">
          <el-time-picker
            arrow-control
            v-model="taskform.endTime"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            placeholder="选择关闭时间"
            value-format="HH:mm"
            format="HH:mm"
            @change="getTime('end')"
            class="w-100"
          >
          </el-time-picker>
        </el-form-item>
        <!-- <div v-if="taskform.type === 'Bool'">
          <el-form-item label="开启状态">
            <el-switch v-model="taskform.startValue"></el-switch>
          </el-form-item>
          <el-form-item label="关闭状态">
            <el-switch v-model="taskform.stopValue"></el-switch>
          </el-form-item>
        </div> -->
        <!-- <div v-if="taskform.type === 'Float32'">
          <el-form-item label="开启状态">
            <el-input
              v-model="taskform.startValue"
              placeholder="输入开启状态,数字"
            ></el-input>
          </el-form-item>
          <el-form-item label="关闭状态">
            <el-input
              v-model="taskform.stopValue"
              placeholder="输入关闭状态,数字"
            ></el-input>
          </el-form-item>
        </div> -->
        <el-form-item label="是否重复">
          <el-select
            placeholder="选择周期"
            v-model="taskform.week"
            class="w-100"
          >
            <el-option
              v-for="item in taskRange"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            placeholder="选择"
            v-model="taskform.custom"
            multiple
            v-if="taskform.week === 'custom'"
            class="w-100"
          >
            <el-option
              v-for="item in week"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定时任务">
          <el-switch v-model="taskform.enable"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTaskVisible = false">取 消</el-button>
        <el-button type="primary" @click="insertTask">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="绑定设备" :visible.sync="dialogBindVisible" width="500px">
      <el-form size="small" label-width="150px">
        <el-form-item label="资源地址">
          <el-select placeholder="请选择资源" v-model="bindform.regAddr">
            <el-option
              v-for="item in resourceList"
              :label="item.name"
              :value="item.addr"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="远程设备id">
          <el-select
            placeholder="请选择设备"
            v-model="bindform.remoteDeviceId"
            @change="getresourceListRemote(bindform.remoteDeviceId)"
          >
            <el-option
              v-for="item in deviceList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="远程资源地址">
          <el-select
            placeholder="请选择远程资源"
            v-model="bindform.remoteRegAddr"
          >
            <el-option
              v-for="item in resourceRemoteList"
              :label="item.name"
              :value="item.addr"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogBindVisible = false">取 消</el-button>
        <el-button type="primary" @click="insertBind">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="报警设置"
      :visible.sync="dialogResourceWarnVisible"
      width="400px"
    >
      <!-- <el-form size="small" label-width="80px">
        <div v-if="warnType === 'Float32'">
          <el-form-item label="报警上限">
            <el-input
              placeholder="请输入报警上限"
              v-model="warnform.up"
              :disabled="$store.state.deviceRow.permissions.conf === false"
            ></el-input>
          </el-form-item>
          <el-form-item label="报警下限">
            <el-input
              placeholder="请输入报警下限"
              v-model="warnform.down"
              :disabled="$store.state.deviceRow.permissions.conf === false"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="报警模式">
          <el-select
            placeholder="请选择报警模式"
            v-model="warnform.mode"
            class="w-100"
            :disabled="$store.state.deviceRow.permissions.conf === false"
          >
            <el-option
              v-for="(item, index) in warnform.items"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
            <el-option label="上限报警" value="1"></el-option>
            <el-option label="下限报警" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form> -->
      <el-form
        ref="warnItemsFormRef"
        :model="warnFormModel"
        size="small"
        label-width="180px"
      >
        <div v-for="item in warnItemsData" :key="item.key">
          <el-form-item
            v-if="item.type === 'float'"
            :label="item.lable"
            :prop="item.key"
            :rules="[
              {
                required: warnRulesObj[item.key] ? true : false,
                message: '请输入' + item.lable,
                trigger: 'change',
              },
            ]"
          >
            <el-input
              :placeholder="'请输入' + item.lable"
              v-model="item.value"
              :disabled="$store.state.deviceRow.permissions.conf === false"
              oninput="value=value.replace(/^0+[0-9]/g, '0').replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');"
            ></el-input>
            <p
              v-if="item.describe"
              style="width: 100%; display: block; font-size: 13px; line-height: 1.5; color: #999999; text-align: justify; margin: 0;"
            >
              {{ item.describe }}
            </p>
          </el-form-item>
          <el-form-item
            v-if="item.type === 'enum'"
            :label="item.lable"
            :prop="item.key"
            :rules="[
              {
                required: warnRulesObj[item.key] ? true : false,
                message: '请选择' + item.lable,
                trigger: 'change',
              },
            ]"
          >
            <el-select
              :placeholder="'请选择' + item.lable"
              v-model="item.value"
              class="w-100"
              :disabled="$store.state.deviceRow.permissions.conf === false"
            >
              <el-option
                v-for="(warnItem, index) in item.items"
                :key="index"
                :label="warnItem.v"
                :value="warnItem.k"
              ></el-option>
            </el-select>
            <p
              v-if="item.describe"
              style="width: 100%; display: block; font-size: 13px; line-height: 1.5; color: #999999; text-align: justify; margin: 0;"
            >
              {{ item.describe }}
            </p>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResourceWarnVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateWarn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改资源信息"
      :visible.sync="dialogResourceInfoVisible"
      width="300px"
    >
      <el-form size="small" label-width="50px">
        <el-form-item label="地址">
          <el-input
            placeholder="请输入地址"
            v-model="resourceInfo.addr"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            placeholder="请输入名称"
            v-model="resourceInfo.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input
            placeholder="请输入单位"
            v-model="resourceInfo.unit"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResourceInfoVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateResourceInfo">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改资源配置"
      :visible.sync="dialogResourceConfigVisible"
      width="450px"
    >
      <div
        class="childDeviceConf"
        v-for="(item, index) in childDeviceConf"
        :key="index"
      >
        <span>{{ item.name }}</span>

        <el-select
          v-if="item.type === 'Enum'"
          v-model="item.value"
          placeholder="请选择"
          :disabled="$store.state.deviceRow.permissions.conf === false"
        >
          <el-option
            v-for="dex in item.items"
            :key="dex.k"
            :value="dex.k"
            :label="dex.v"
          ></el-option>
        </el-select>
        <el-input
          width="50%"
          v-else
          v-model="item.value"
          :disabled="$store.state.deviceRow.permissions.conf === false"
        ></el-input>
        <el-button
          @click="updateResourceConfig(item.addr, item.type, item.value)"
          size="mini"
          :disabled="$store.state.deviceRow.permissions.conf === false"
          >更改</el-button
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogResourceConfigVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="删除绑定"
      :visible.sync="dialogDeleteBindVisible"
      width="500px"
    >
      <el-row>
        <el-col class="text-center">
          确定从 绑定 列表删除吗？
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteBindVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteBind()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="删除被绑定"
      :visible.sync="dialogDeleteBeBindVisible"
      width="500px"
    >
      <el-row>
        <el-col class="text-center">
          确定从 被绑定 列表删除吗？
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteBeBindVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteBeBind()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="删除任务"
      :visible.sync="dialogDeleteTaskVisible"
      width="500px"
    >
      <el-row>
        <el-col class="text-center">
          确定删除吗？
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteTaskVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteTask()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="清空绑定"
      :visible.sync="dialogClearVisible"
      width="500px"
    >
      <el-row>
        <el-col class="text-center">
          确定清空所有绑定与被绑定吗？
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClearVisible = false">取 消</el-button>
        <el-button type="primary" @click="clearBind()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="编辑定时任务"
      :visible.sync="dialogUpdateTaskVisible"
      width="400px"
    >
      <el-form size="small" label-width="100px" v-model="taskInfo">
        <el-form-item label="资源地址">
          <el-select
            placeholder="请选择资源"
            v-model="taskInfo.addr"
            @change="getResourceType('update')"
            class="w-100"
          >
            <el-option
              v-for="item in resourceList.filter((item) => item.rw)"
              :label="item.name"
              :value="item.addr"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开启时间">
          <el-time-picker
            arrow-control
            v-model="taskInfo.startTime"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            placeholder="选择开启时间"
            value-format="HH:mm"
            format="HH:mm"
            @change="getTimeUpdate('start')"
            class="w-100"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="关闭时间">
          <el-time-picker
            arrow-control
            v-model="taskInfo.endTime"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            placeholder="选择关闭时间"
            value-format="HH:mm"
            format="HH:mm"
            @change="getTimeUpdate('end')"
            class="w-100"
          >
          </el-time-picker>
        </el-form-item>
        <!-- <div v-if="taskInfo.type === 'Bool'">
          <el-form-item label="开启状态">
            <el-switch v-model="taskInfo.startValue"></el-switch>
          </el-form-item>
          <el-form-item label="关闭状态">
            <el-switch v-model="taskInfo.stopValue"></el-switch>
          </el-form-item>
        </div> -->
        <!-- <div v-if="taskInfo.type === 'Float32'">
          <el-form-item label="开启状态">
            <el-input
              v-model="taskInfo.startValue"
              placeholder="输入开启状态,数字"
            ></el-input>
          </el-form-item>
          <el-form-item label="关闭状态">
            <el-input
              v-model="taskInfo.stopValue"
              placeholder="输入关闭状态,数字"
            ></el-input>
          </el-form-item>
        </div> -->
        <el-form-item label="是否重复">
          <el-select
            placeholder="选择周期"
            v-model="taskInfo.week"
            class="w-100"
          >
            <el-option
              v-for="item in taskRange"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            placeholder="选择"
            v-model="taskInfo.custom"
            multiple
            v-if="taskInfo.week === 'custom'"
            class="w-100"
            @change="$forceUpdate()"
          >
            <el-option
              v-for="item in week"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定时任务">
          <el-switch v-model="taskInfo.enable"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogUpdateTaskVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTask()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改设备参数"
      :visible.sync="changeParaVisible"
      width="30%"
    >
      <div class="childDeviceConf" v-for="(item, index) in devs" :key="index">
        <span>{{ item.name }}</span>
        <el-select
          v-if="item.type === 'Enum'"
          v-model="item.value"
          placeholder="请选择"
          :disabled="!dev && !rw"
        >
          <el-option
            v-for="dex in item.items"
            :key="dex.k"
            :value="dex.k"
            :label="dex.v"
          ></el-option>
        </el-select>
        <el-input width="50%" v-else v-model="item.value"></el-input>
        <el-button
          @click="changeDevs(item.addr, item.type, item.value)"
          size="mini"
          >更改</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="ValueDialogVisible"
      width="20%"
      :append-to-body="true"
    >
      <el-input
        v-model="editValueForm.value"
        placeholder="请输入内容"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ValueDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="orderValue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { Export2Excel } from "../../assets/api/Export2Excel.js";
import * as echarts from "echarts";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import device from "../../assets/api/device.js";
import Breadcrumb from "../components/breadCrumb.vue";
import { arrange } from "../../utils/arrange.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      // 资源历史记录分页
      resourceHistoryCurrentPage: 1,
      resourceHistoryPageSize: 100,
      // 数据
      groupLoading: false,
      tableHeight: 0,
      activeBtn: "",
      historyLoading: false,
      historyType: "",
      childDeviceConf: [],
      deviceOnline: "",
      deviceRow: {},
      conf: "",
      dev: "",
      resourceRow: {},
      resourceInfo: {
        addr: "",
        deviceId: "",
        index: 0,
        name: "",
        unit: "",
      },
      warnType: "Bool",
      groupid: "",
      deviceid: "",
      resourceid: "",
      activeName: "first",
      deleteBindid: "",
      deleteBeBindid: "",
      taskInfo: "",
      groupList: [],
      deviceList: [],
      resourceList: [],
      resourceRemoteList: [],
      bread: ["设备列表", ""],
      controlTableData: [],
      warnTableData: [],
      tableData: [],
      tableDataTask: [],
      tableDataResource: [],
      tableDataBind: [],
      tableDataBeBind: [],
      tableDataResourceHistory: [],
      dialogControlVisible: false,
      dialogHistoryVisible: false,
      dialogTaskVisible: false,
      dialogBindVisible: false,
      dialogDeleteBindVisible: false,
      dialogDeleteBeBindVisible: false,
      dialogDeleteTaskVisible: false,
      dialogUpdateTaskVisible: false,
      dialogClearVisible: false,
      dialogResourceWarnVisible: false,
      dialogResourceInfoVisible: false,
      dialogResourceConfigVisible: false,
      dialogResourceHistoryVisible: false,
      changeParaVisible: false,
      ValueDialogVisible: false,
      devs: [],
      editValueForm: [],
      warnform: {
        addr: 0,
        deviceId: 0,
        up: 0,
        down: 0,
        mode: 0,
        items: [],
      },
      warnRulesObj: {
        booleanWarn: true,
        floatWarn: true,
      },
      warnItemsRow: {},
      warnItemsData: [],
      bindform: {
        regAddr: "",
        remoteDeviceId: "",
        remoteRegAddr: "",
      },
      taskform: {
        range: "",
        startTime: "",
        endTime: "",
        startHour: "",
        startMinute: "",
        stopHour: "",
        stopMinute: "",
        week: "",
        enable: false,
        addr: "",
        startValue: false,
        stopValue: false,
        type: "Bool",
        custom: "",
      },
      week: [
        {
          label: "周一",
          value: 1,
        },
        {
          label: "周二",
          value: 2,
        },
        {
          label: "周三",
          value: 3,
        },
        {
          label: "周四",
          value: 4,
        },
        {
          label: "周五",
          value: 5,
        },
        {
          label: "周六",
          value: 6,
        },
        {
          label: "周日",
          value: 7,
        },
      ],
      queryControl: {
        deviceId: "",
        index: 1,
        size: 50,
        startTime: "",
        endTime: "",
        total: 0,
      },
      queryWarn: {
        deviceId: "",
        index: 1,
        size: 50,
        startTime: "",
        endTime: "",
        total: 0,
      },
      queryResource: {
        deviceId: "",
        addr: "",
        isBool: "",
        startTime: "",
        endTime: "",
      },
      taskRange: [
        {
          value: "7",
          label: "只执行一次",
        },
        {
          value: "every",
          label: "每天",
        },
        {
          value: "week",
          label: "周一至周五",
        },
        {
          value: "custom",
          label: "自定义",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pickerOptionsShort: {
        shortcuts: [
          {
            text: "最近一天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近七天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      valueWarn: "",
      valueControl: "",
      valueResource: "",
      configSelectList: [],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          show: true,
          feature: {
            myToolsNum: {
              show: true,
              title: "下载Excel",
              // icon: "image://" + require("../../img/xiazai.png"),
              onclick: () => {
                this.excel2();
              },
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: "",
        },
        yAxis: {
          type: "value",
          axisPointer: {
            snap: true,
          },
        },
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              lte: 6,
              color: "green",
            },
            {
              gt: 6,
              lte: 8,
              color: "red",
            },
            {
              gt: 8,
              lte: 14,
              color: "green",
            },
            {
              gt: 14,
              lte: 17,
              color: "red",
            },
            {
              gt: 17,
              color: "green",
            },
          ],
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            data: "",
            markPoint: {
              symbol: "pin", //标记(气泡)的图形
              symbolSize: 20, //标记(气泡)的大小
              itemStyle: {
                color: "#4587E7", //图形的颜色。
                borderColor: "#000", //图形的描边颜色。支持的颜色格式同 color，不支持回调函数。
                borderWidth: 0, //描边线宽。为 0 时无描边。
                borderType: "solid", //柱条的描边类型，默认为实线，支持 ‘solid’, ‘dashed’, ‘dotted’。
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                },
                {
                  type: "min",
                  name: "最小值",
                },
              ],
            },
            markLine: {
              data: [
                {
                  type: "average",
                  name: "平均值",
                },
              ],
            },
          },
        ],
        dataZoom: [
          {
            // 第一个 dataZoom 组件
            xAxisIndex: 0, // 表示这个 dataZoom 组件控制 第一个
            type: "inside",
          },
        ],
      },
      BooleanOption: {
        animation: true,
        title: {
          text: "资源开关",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            snap: true,
            axis: "x",
          },
        },
        legend: {
          data: ["Step End"],
        },
        // grid: {
        //   show: true,
        //   containLabel: true,
        // },
        grid: {
          show: true,
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            myToolsNum: {
              show: true,
              title: "下载Excel",
              // icon: "image://" + require("../../img/xiazai.png"),
              onclick: () => {
                this.excel1();
              },
            },
          },
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: true,
          },
          // nameGap: 0,
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          // axisLabel: {
          //   interval: 0,
          //   show: true,
          // },
        },
        yAxis: {
          type: "category",
          splitLine: {
            show: true,
          },
          data: ["关", "开"],
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          axisLabel: {
            interval: 0,
            show: true,
          },
          splitArea: {
            interval: 0,
          },
        },
        series: [
          {
            name: "",
            type: "line",
            step: "start",
            data: [],
          },
        ],
        dataZoom: [
          {
            // 第一个 dataZoom 组件
            xAxisIndex: 0, // 表示这个 dataZoom 组件控制 第一个
            type: "inside",
          },
        ],
      },
    };
  },
  filters: {
    formatAnalog(item) {
      if (item.type != "Float32") {
        return item.value;
      }

      var val = item.value;
      if (val == 0) {
        val = "0";
      }
      if (val) {
        val = val.toString().replace(/\$|\,/g, "");
        const sign = val == (val = Math.abs(val));
        val = Math.floor(val * 100 + 0.50000000001);
        let cents = val % 100;
        val = Math.floor(val / 100).toString();
        if (cents < 10) {
          cents = "0" + cents;
        }
        for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
          val =
            val.substring(0, val.length - (4 * i + 3)) +
            "," +
            val.substring(val.length - (4 * i + 3));
        }
        return (sign ? "" : "-") + val + "." + cents;
      }
    },
    switchWeek(val) {
      switch (val) {
        case 1:
          val = "一";
          break;
        case 2:
          val = "二";
          break;
        case 3:
          val = "三";
          break;
        case 4:
          val = "四";
          break;
        case 5:
          val = "五";
          break;
        case 6:
          val = "六";
          break;
        case 0:
          val = "日";
          break;
      }
      return val;
    },
    utcMoment(d) {
      if (!d) {
        return "--";
      }
      var i = -(new Date().getTimezoneOffset() / 60);
      var len = new Date(d).getTime();
      var utcTime = len;
      return moment(new Date(utcTime + 3600000 * i)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
  },
  computed: {
    warnFormModel() {
      return Object.fromEntries(
        this.warnItemsData.map((m) => [m.key, m.value])
      );
    },
  },
  mounted() {
    this.getdeviceGroup();

    this.$nextTick(() => {
      this.tableHeight =
        window.innerHeight - this.$refs.tableDevice.$el.offsetTop - 300;

      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight =
          window.innerHeight - self.$refs.tableDevice.$el.offsetTop - 300;
      };
    });
    //this.$refs.table.$el.offsetTop：表格距离浏览器的高度
  },
  methods: {
    rowClick(row) {
      this.deviceOnline = row.online;
      this.getresourceByDevice(row, row.id);
    },
    utcMoment(d) {
      if (!d) {
        return "--";
      }
      var i = -(new Date().getTimezoneOffset() / 60);
      var len = new Date(d).getTime();
      var utcTime = len;
      return moment(new Date(utcTime + 3600000 * i)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    getNowFormatDate(type) {
      var day = new Date();
      var Year = 0;
      var Month = 0;
      var Day = 0;
      var CurrentDate = "";
      //初始化时间
      //Year       = day.getYear();//有火狐下2008年显示108的bug
      if (type === "yesterday") {
        day.setDate(day.getDate() - 1);
      }
      Year = day.getFullYear(); //ie火狐下都可以
      Month = day.getMonth() + 1;
      Day = day.getDate();
      CurrentDate += Year + "-";
      if (Month >= 10) {
        CurrentDate += Month + "-";
      } else {
        CurrentDate += "0" + Month + "-";
      }
      if (Day >= 10) {
        CurrentDate += Day;
      } else {
        CurrentDate += "0" + Day;
      }
      return CurrentDate;
    },
    //修改设备参数
    async changeDevs(addr, type, value) {
      const body = {
        deviceId: this.deviceid,
        regs: [
          {
            addr: addr,
            type: type,
            value: value,
          },
        ],
      };
      await device.getDeviceWriteReg(body).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.getdeviceByGroup(this.groupid, this.activeBtn);
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    //获取设备参数
    handlePara(row) {
      this.dev = row.permissions.dev;
      this.deviceid = row.id;
      device
        .getDeviceDevs({
          deviceId: row.id,
        })
        .then((res) => {
          if (res.data == undefined) {
            this.$message.error("设备参数为空");
          } else {
            this.devs = res.data;
            this.changeParaVisible = true;
          }
        });
    },
    //获取资源历史记录
    async getResourceHistory(info) {
      this.historyLoading = true;
      this.resourceRow = info;
      if (this.valueResource === "") {
        this.queryResource.startTime = this.getNowFormatDate("") + " 00:00:00";
        this.queryResource.endTime = this.getNowFormatDate("") + " 23:59:59";
        this.valueResource = [
          this.queryResource.startTime,
          this.queryResource.endTime,
        ];
      } else {
        this.queryResource.startTime = this.valueResource[0];
        this.queryResource.endTime = this.valueResource[1];
      }

      this.queryResource.addr = this.resourceRow.addr;
      this.queryResource.deviceId = this.deviceid;
      if (info.type === "Bool") {
        this.queryResource.isBool = true;
      } else {
        this.queryResource.isBool = false;
      }
      await device.getResourceHistory(this.queryResource).then(
        (res) => {
          if (res.code === 200) {
            this.historyLoading = false;
            this.dialogResourceHistoryVisible = true;
            if (this.queryResource.isBool === true) {
              if (res.data) {
                let newData = res.data.map((item) => {
                  let onLine = item.data === "true" ? "开" : "关";
                  let time = this.utcMoment(item.time);
                  return {
                    value: [time, onLine],
                  };
                });
                this.BooleanOption.series[0].data = newData;
                this.$nextTick(() => {
                  this.myChart = echarts.init(
                    document.getElementById("regmain")
                  );
                  this.myChart.resize();
                  this.myChart.setOption(this.BooleanOption);
                });

                this.tableDataResourceHistory = res.data;
                for (let i in this.tableDataResourceHistory) {
                  this.tableDataResourceHistory[i].time = this.utcMoment(
                    this.tableDataResourceHistory[i].time
                  );
                }
              } else {
                this.$nextTick(() => {
                  this.myChart = echarts.init(
                    document.getElementById("regmain")
                  );
                  this.myChart.resize();
                  this.myChart.setOption(this.BooleanOption);
                });
              }
            } else {
              if (res.data) {
                this.tableDataResourceHistory = res.data;
                let newData = res.data.map((item) => {
                  return (item.time = this.utcMoment(item.time));
                });
                let x = this.arrDataBy(res.data, "time");
                let y = this.arrDataBy(res.data, "data");
                this.option.xAxis.data = x;
                this.option.series[0].data = y;
                this.$nextTick(() => {
                  this.myChart = echarts.init(
                    document.getElementById("regmain")
                  );
                  this.myChart.resize();
                  this.myChart.setOption(this.option);
                });
              } else {
                this.$nextTick(() => {
                  this.myChart = echarts.init(
                    document.getElementById("regmain")
                  );
                  this.myChart.resize();
                  this.myChart.setOption(this.option);
                });
              }
            }
          } else {
            this.$message.error("获取资源历史失败");
            this.historyLoading = false;
            return;
          }
        },
        () => {
          this.$message.error("获取资源历史失败");
          this.historyLoading = false;
          return;
        }
      );
    },
    //获取设备分组
    async getdeviceGroup() {
      this.groupLoading = true;
      await device.getdeviceGroup().then(
        (res) => {
          this.groupLoading = false;
          if (res.code === 200) {
            this.groupList = res.data;
          } else {
            this.$message.error("获取设备分组失败");
            return;
          }
        },
        () => {
          this.groupLoading = false;
          this.$message.error("获取设备分组失败");
          return;
        }
      );
    },
    //通过分组获取设备信息
    async getdeviceByGroup(id, index) {
      this.activeBtn = index;
      this.groupid = id;
      this.$store.state.groupid = id;
      this.$store.state.tableDataResource = [];
      this.deviceid = "";
      this.$store.state.deviceRow = {
        permissions: {
          conf: false,
        },
      };
      this.deviceOnline = "";
      await device
        .getdeviceByGroup({
          groupId: id,
        })
        .then(
          (res) => {
            if (res.code !== 200) {
              this.$message.error("获取设备数据失败");
              return;
            } else {
              this.$store.state.tableDataDevice = res.data;
            }
          },
          () => {
            this.$message.error("建立连接失败");
          }
        );
    },
    //获通过设备id获取资源
    async getresourceByDevice(row, id) {
      this.$store.state.deviceRow = row;
      this.$store.state.deviceid = id;
      this.deviceid = id;
      await device
        .getresourceByDevice({
          deviceId: id,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$store.state.deviceid = id;
              let data = JSON.parse(JSON.stringify(res.data));
              this.tableDataResource = [];
              //检测每个资源的show字段，并且修改bool型的value值
              if (data.length != 0) {
                data.forEach((v) => {
                  if (!v.hasOwnProperty("show") || v.show) {
                    if (v.value == "true") {
                      v.value = true;
                    } else if (v.value == "false") {
                      v.value = false;
                    }
                    this.tableDataResource.push(v);
                  }
                });
              }

              this.$store.state.tableDataResource = this.tableDataResource;
            } else {
              this.$message.error("获取资源数据失败");
              return;
            }
          },
          () => {
            this.$message.error("获取资源数据失败");
            return;
          }
        );
    },
    // 刷新
    async getRefreshDevice(deviceId) {
      await device.getRefreshDevice({ deviceId }).then(
        () => {
          if (res.code !== 200) {
            this.$message.error("刷新失败");
          }
        },
        () => {
          this.$message.error("刷新失败");
        }
      );
    },
    //更新设备信息
    async updateDevice() {
      await this.$refs.updateform.validate((valid) => {
        if (valid) {
          device.updateDevice(this.updateform).then(
            (res) => {
              if (res.code !== 200) {
                return this.$message.error("修改设备失败");
              } else {
                this.$message.success("修改设备成功");
                this.getdeviceList();
                this.dialogVisible = false;
              }
            },
            () => {
              this.$message.error("建立连接失败");
            }
          );
        }
      });
    },
    //同时调用两个历史记录
    async getdeviceHistroy(info) {
      this.deviceid = info.id;
      this.dialogHistoryVisible = true;
      this.queryControl.deviceId = this.deviceid;
      this.queryWarn.deviceId = this.deviceid;
      this.getControlHistroy();
      this.getWarnHistroy();
    },
    //获取操作历史
    async getControlHistroy() {
      if (this.valueControl === "") {
        this.queryControl.startTime =
          this.getNowFormatDate("yesterday") + " 00:00:00";
        this.queryControl.endTime = this.getNowFormatDate("") + " 23:59:59";
        this.valueControl = [
          this.queryControl.startTime,
          this.queryControl.endTime,
        ];
      } else {
        this.queryControl.startTime = this.valueControl[0];
        this.queryControl.endTime = this.valueControl[1];
      }
      await device.getControlHistroy(this.queryControl).then(
        (res) => {
          if (res.code === 200) {
            let data = res.data;
            data.forEach((item) => {
              item.date = this.formatDate(
                new Date(new Date(item.date).getTime() + 28800000),
                "s"
              );
            });
            this.controlTableData = data;

            this.queryControl.total = res.total;
          } else {
            this.$message.error("获取操作历史失败");
            return;
          }
        },
        () => {
          this.$message.error("获取操作历史失败");
          return;
        }
      );
    },
    //获取报警历史记录
    async getWarnHistroy() {
      if (this.valueWarn === "") {
        this.queryWarn.startTime =
          this.getNowFormatDate("yesterday") + " 00:00:00";
        this.queryWarn.endTime = this.getNowFormatDate("") + " 23:59:59";
        this.valueWarn = [this.queryWarn.startTime, this.queryWarn.endTime];
      } else {
        this.queryWarn.startTime = this.valueWarn[0];
        this.queryWarn.endTime = this.valueWarn[1];
      }
      await device.getWarnHistroy(this.queryWarn).then(
        (res) => {
          if (res.code === 200) {
            let data = res.data;
            this.warnTableData = res.data;
            data.forEach((item) => {
              item.time = this.formatDate(
                new Date(new Date(item.time).getTime() + 28800000),
                "s"
              );
            });
            this.warnTableData = data;
            this.queryWarn.total = res.total;
          } else {
            this.$message.error("获取报警历史失败");
            return;
          }
        },
        () => {
          this.$message.error("获取报警历史失败");
          return;
        }
      );
    },
    //设备全开 全关
    async controlAll(info, status) {
      await device
        .controlAll({
          deviceId: info.id,
          status: status,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getresourceByDevice(
              this.$store.state.deviceRow,
              this.deviceid
            );
          }
        });
    },
    //获取设备操作
    async getTask(id) {
      this.deviceid = id;
      this.dialogControlVisible = true;
      await device
        .getTask({
          deviceId: id,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.tableDataTask = res.data.tasks;
            } else {
              this.$message.error("获取操作失败");
              return;
            }
          },
          () => {
            this.$message.error("获取操作失败");
            return;
          }
        );
      //获取资源列表
      this.getresourceList(id);
      this.getdeviceList("");
    },
    //获取绑定信息
    async getBind(resourceid) {
      await device
        .getBind({
          deviceId: this.deviceid,
          regAddr: resourceid,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.tableDataBind = res.data.bind;
              this.tableDataBeBind = res.data.beBind;
            } else {
              this.$message.error("获取绑定列表失败");
              return;
            }
          },
          () => {
            this.$message.error("获取绑定列表失败");
            return;
          }
        );
    },
    //获取资源列表
    async getresourceList(id) {
      await device
        .getresourceByDevice({
          deviceId: id,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.resourceList = res.data;
              this.resourceid = res.data[0].addr;
            } else {
              this.$message.error("获取资源列表失败");
              return;
            }
          },
          () => {
            this.$message.error("获取资源列表失败");
            return;
          }
        );
    },
    //获取设备列表
    async getdeviceList(id) {
      await device
        .getdeviceByGroup({
          groupId: id,
        })
        .then(
          (res) => {
            if (res.code !== 200) {
              this.$message.error("获取设备数据失败");
              return;
            } else {
              this.deviceList = res.data;
            }
          },
          () => {
            this.$message.error("建立连接失败");
          }
        );
    },
    //获取远程资源列表
    async getresourceListRemote(id) {
      await device
        .getresourceByDevice({
          deviceId: id,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.resourceRemoteList = res.data;
            } else {
              this.$message.error("获取远程资源列表失败");
              return;
            }
          },
          () => {
            this.$message.error("获取远程资源列表失败");
            return;
          }
        );
    },
    //添加定时任务
    async insertTask() {
      this.getWeek(this.taskform.week);
      if (this.taskform.week == "custom") {
        this.taskform.week = this.taskform.custom;
      }
      let params = {
        deviceId: this.deviceid,
        task: this.taskform,
      };
      await device.insertTask(params).then(
        (res) => {
          if (res.code === 200) {
            this.taskform = {
              range: "",
              startTime: "",
              endTime: "",
              startHour: "",
              startMinute: "",
              stopHour: "",
              stopMinute: "",
              week: "",
              enable: false,
              addr: "",
              startValue: false,
              stopValue: false,
              type: "Bool",
              custom: "",
            };
            this.$message.success("添加成功");
            this.dialogTaskVisible = false;
            this.getTask(this.deviceid);
          } else {
            this.$message.error("添加失败");
            return;
          }
        },
        () => {
          this.$message.error("添加失败");
          return;
        }
      );
    },
    //更新任务
    async updateTask() {
      this.getWeekUpdate(this.taskInfo.week);
      if (this.taskInfo.week == "custom") {
        this.taskInfo.week = this.taskInfo.custom;
      }
      this.taskInfo.startValue = this.taskInfo.startValue.toString();
      this.taskInfo.stopValue = this.taskInfo.stopValue.toString();
      let params = {
        deviceId: this.deviceid,
        task: this.taskInfo,
      };
      console.log(JSON.stringify(params));
      await device.updateTask(params).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("修改成功");
            this.dialogUpdateTaskVisible = false;
            this.getTask(this.deviceid);
          } else {
            this.$message.error("修改失败");
            return;
          }
        },
        () => {
          this.$message.error("修改失败");
          return;
        }
      );
    },
    //更新任务状态
    async updateTaskStatus(info) {
      let status = "";
      console.log(info.enable);
      if (info.enable === true) {
        status = true;
      } else if (info.enable === false) {
        status = false;
      }
      let object = info;
      object.enable = status;
      await device
        .updateTask({
          deviceId: this.deviceid,
          task: object,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.getTask(this.deviceid);
            } else {
              this.$message.error("操作失败");
              return;
            }
          },
          () => {
            this.$message.error("操作失败");
            return;
          }
        );
    },
    //绑定设备
    async insertBind() {
      this.bindform.deviceId = this.deviceid;
      await device.insertBind(this.bindform).then(
        (res) => {
          if (res.code === 200) {
            this.dialogBindVisible = false;
            this.$message.success("绑定成功");
            this.bindform = {
              regAddr: "",
              remoteDeviceId: "",
              remoteRegAddr: "",
            };
            this.getBind(this.resourceid);
          } else {
            this.$message.error("绑定失败");
            return;
          }
        },
        () => {
          this.$message.error("绑定失败");
          return;
        }
      );
    },
    //删除绑定
    async deleteBind() {
      let params = {
        remoteRegAddr: this.deleteBindid.remoteRegAddr,
        deviceId: this.deviceid,
        regAddr: this.resourceid,
        remoteDeviceId: this.deleteBindid.remoteDeviceid,
      };
      await device.deleteBind(params).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.dialogDeleteBindVisible = false;
            this.getBind(this.resourceid);
          } else {
            this.$message.error("删除失败");
            return;
          }
        },
        () => {
          this.$message.error("删除失败");
          return;
        }
      );
    },
    //删除被绑定
    async deleteBeBind() {
      let params = {
        regAddr: this.deleteBeBindid.regAddr,
        deviceId: this.deviceid,
        remoteDeviceId: this.deleteBeBindid.remoteDeviceid,
        remoteRegAddr: this.deleteBeBindid.remoteRegAddr,
      };
      await device.deleteBeBind(params).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.dialogDeleteBeBindVisible = false;
            this.getBind(this.resourceid);
          } else {
            this.$message.error("删除失败");
            return;
          }
        },
        () => {
          this.$message.error("删除失败");
          return;
        }
      );
    },
    //删除任务
    async deleteTask() {
      await device
        .deleteTask({
          deviceId: this.deviceid,
          taskId: this.TaskInfo.taskId,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("删除成功");
              this.dialogDeleteTaskVisible = false;
              this.getTask(this.deviceid);
            } else {
              this.$message.error("删除失败");
              return;
            }
          },
          () => {
            this.$message.error("删除失败");
            return;
          }
        );
    },
    //清空绑定
    async clearBind() {
      await device
        .clearBind({
          deviceId: this.deviceid,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("清空成功");
              this.dialogClearVisible = false;
              this.getBind(this.resourceid);
            } else {
              this.$message.error("清空失败");
              return;
            }
          },
          () => {
            this.$message.error("清空失败");
            return;
          }
        );
    },
    //获取资源报警设置 通过设备id 资源id
    async getResourceWarn(info) {
      await device
        .getWarnMoreConfig({
          addr: info.addr,
          deviceId: this.deviceid,
        })
        .then(
          (res) => {
            if (res.code === 200) {
              this.dialogResourceWarnVisible = true;
              this.warnItemsRow = {
                addr: Number(res.data.addr),
                deviceId: Number(res.data.deviceId),
              };
              this.warnItemsData = res.data.items;
            } else {
              this.$message.error("获取报警设置失败");
            }
          },
          () => {
            this.$message.error("获取报警设置失败");
          }
        );
      // this.resourceid = info.addr;
      // if (info.type === "Float32") {
      //   this.warnType = "Float32";
      // } else {
      //   this.warnType = "Bool";
      // }

      // await device
      //   .getResourceWarn({
      //     addr: info.addr,
      //     deviceId: this.deviceid,
      //   })
      //   .then(
      //     (res) => {
      //       if (res.code === 200) {
      //         this.dialogResourceWarnVisible = true;
      //         this.warnform.mode = res.data.mode.toString();
      //         this.warnform.up = res.data.up;
      //         this.warnform.down = res.data.down;
      //         this.warnform.items = res.data.items?.map((m) => {
      //           return {
      //             label: m.v,
      //             value: m.k,
      //           };
      //         });
      //       } else {
      //         this.$message.error("获取报警设置失败");
      //         return;
      //       }
      //     },
      //     () => {
      //       this.$message.error("获取报警设置失败");
      //       return;
      //     }
      //   );
    },
    //修改资源报警设置
    updateWarn() {
      this.$refs.warnItemsFormRef.validate(async (valid) => {
        if (!valid) return;
        const fieds = ["booleanWarn", "floatWarn"];
        const entriesModel = Object.entries(this.warnFormModel).map((m) => {
          const k = fieds.includes(m[0]) ? "mode" : m[0];
          const v = fieds.includes(m[0]) ? Number(m[1]) : m[1];
          return [k, v];
        });
        const data = {
          ...this.warnItemsRow,
          ...Object.fromEntries(entriesModel),
        };
        await device.updateWarnMoreConfig(data).then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("修改报警设置成功");
              this.dialogResourceWarnVisible = false;
            } else {
              this.$message.error("修改失败");
            }
          },
          () => {
            this.$message.error("修改失败");
          }
        );
      });
      // this.warnform.addr = this.resourceid;
      // this.warnform.deviceId = this.deviceid;
      // await device.updateWarn(this.warnform).then(
      //   (res) => {
      //     if (res.code === 200) {
      //       this.$message.success("修改报警设置成功");
      //       this.getresourceByDevice(
      //         this.$store.state.deviceRow,
      //         this.deviceid
      //       );
      //       this.dialogResourceWarnVisible = false;
      //     } else {
      //       this.$message.error("修改失败");
      //       return;
      //     }
      //   },
      //   () => {
      //     this.$message.error("修改失败");
      //     return;
      //   }
      // );
    },
    //更新资源数据
    async updateResourceInfo() {
      await device.updateResourceInfo(this.resourceInfo).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("修改成功");
            this.dialogResourceInfoVisible = false;
            this.getresourceByDevice(
              this.$store.state.deviceRow,
              this.deviceid
            );
          } else {
            this.$message.error("修改失败");
            return;
          }
        },
        () => {
          this.$message.error("修改失败");
          return;
        }
      );
    },
    //获取资源参数
    async getResourceConfig(item) {
      const body = {
        addr: item.addr,
        deviceId: this.deviceid,
      };
      device.getResourceConfig(body).then((res) => {
        if (res.data) {
          res.data.forEach((item, index) => {
            if (item.type == "Float32") {
              item.value = Number(item.value).toFixed(2);
            }
          });
          this.childDeviceConf = res.data;
          this.dialogResourceConfigVisible = true;
        } else {
          this.$message("设置参数为空");
        }
      });
    },
    //修改资源参数
    async updateResourceConfig(addr, type, value) {
      if (this.$store.state.deviceRow.permissions.conf === false) {
        this.$message.error("无权限修改");
        return;
      }
      const body = {
        deviceId: this.deviceid,
        regs: [
          {
            addr: addr,
            type: type,
            value: value,
          },
        ],
      };
      await device.updateResourceConfig(body).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.dialogResourceConfigVisible = false;
          this.getresourceByDevice(this.$store.state.deviceRow, this.deviceid);
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    //修改资源状态
    async updateResourceStatus(info) {
      let status = "";
      if (info.value === true) {
        status = true;
      } else if (info.value === false) {
        status = false;
      }
      let params = {
        deviceId: this.deviceid,
        regs: [
          {
            addr: info.addr,
            type: "Bool",
            value: status,
          },
        ],
      };
      await device.updateResourceConfig(params).then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getresourceByDevice(
              this.$store.state.deviceRow,
              this.deviceid
            );
          } else {
            this.$message.error("操作失败");
            return;
          }
        },
        () => {
          this.$message.error("操作失败");
          return;
        }
      );
    },
    //获取资源信息
    getResourceInfo(info) {
      this.dialogResourceInfoVisible = true;
      let { addr, name, unit, index } = info;
      this.resourceInfo = {
        deviceId: this.deviceid,
        addr: addr,
        name: name,
        unit: unit,
        index: index,
      };
    },
    //删除绑定提示框
    displayDeleteBindDialog(info) {
      this.dialogDeleteBindVisible = true;
      this.deleteBindid = info;
    },
    //删除被绑定提示框
    displayDeleteBeBindDialog(info) {
      this.dialogDeleteBeBindVisible = true;
      this.deleteBeBindid = info;
      console.log(this.deleteBeBindid);
    },
    //删除任务提示框
    displayDeleteTaskDialog(info) {
      this.dialogDeleteTaskVisible = true;
      this.TaskInfo = info;
    },
    //显示清空弹窗
    displayClearDialog() {
      this.dialogClearVisible = true;
    },
    //获取任务信息
    getTaskRowInfo(info) {
      this.dialogUpdateTaskVisible = true;
      this.taskInfo = {
        ...info,
      };
      console.log(info);
      this.taskInfo.startValue = Boolean(this.taskInfo.startValue);
      this.taskInfo.stopValue = Boolean(this.taskInfo.stopValue);
      this.$set(
        this.taskInfo,
        "startTime",
        this.taskInfo.startHour + ":" + this.taskInfo.startMinute + ":00"
      );
      this.$set(
        this.taskInfo,
        "endTime",
        this.taskInfo.stopHour + ":" + this.taskInfo.stopMinute + ":00"
      );
      let week = this.taskInfo.week;
      if (week[0] === 7 && week.length === 1) {
        this.taskInfo.week = "7";
      } else if (week[0] === 1 && week[4] === 5) {
        this.taskInfo.week = "week";
      } else if (week[0] === 0 && week[6] === 6) {
        this.taskInfo.week = "every";
      } else {
        this.taskInfo.week = "custom";
        let arr = [];
        week.forEach((item) => {
          arr.push(item);
        });
        this.taskInfo.custom = arr;
      }
    },
    //时间获取
    getTime(type) {
      if (type === "start") {
        let time = this.taskform.startTime.split(":");
        this.taskform.startHour = time[0];
        this.taskform.startMinute = time[1];
      } else if (type === "end") {
        let time = this.taskform.endTime.split(":");
        this.taskform.stopHour = time[0];
        this.taskform.stopMinute = time[1];
      }
    },
    //时间获取
    getTimeUpdate(type) {
      if (type === "start") {
        let time = this.taskInfo.startTime.split(":");
        this.taskInfo.startHour = time[0];
        this.taskInfo.startMinute = time[1];
      } else if (type === "end") {
        let time = this.taskInfo.endTime.split(":");
        this.taskInfo.stopHour = time[0];
        this.taskInfo.stopMinute = time[1];
      }
    },
    //翻页
    handleSizeChangeControl(val) {
      this.queryControl.size = val;
      this.getControlHistroy();
    },
    handleCurrentChangeControl(val) {
      this.queryControl.index = val;
      this.getControlHistroy();
    },
    handleSizeChangeWarn(val) {
      this.queryWarn.size = val;
      this.getWarnHistroy();
    },
    handleCurrentChangeWarn(val) {
      this.queryWarn.index = val;
      this.getWarnHistroy();
    },
    //时间判断
    timeCheck(val, type) {
      if (type === "control") {
      } else if (type === "warn") {
        if (val === "") {
          this.queryWarn.startTime = "";
          this.queryWarn.endTime = "";
        } else {
          this.queryWarn.startTime = val[0];
          this.queryWarn.endTime = val[1];
        }
      }
    },
    //获取自定义的星期一，二，三…
    getCustomWeek() {
      this.taskform.week = this.taskform.custom;
    },
    getWeek(val) {
      if (val === "7") {
        this.taskform.week = [7];
      } else if (val === "every") {
        this.taskform.week = [0, 1, 2, 3, 4, 5, 6];
      } else if (val === "week") {
        this.taskform.week = [1, 2, 3, 4, 5];
      }
    },
    getWeekUpdate(val) {
      if (val === "7") {
        this.taskInfo.week = [7];
      } else if (val === "every") {
        this.taskInfo.week = [0, 1, 2, 3, 4, 5, 6];
      } else if (val === "week") {
        this.taskInfo.week = [1, 2, 3, 4, 5];
      }
    },
    //根据资源切换值类型 startValue stopValue
    getResourceType(type) {
      if (type === "insert") {
        let value = this.taskform.addr;
        let info = this.resourceList.find((item) => {
          return item.addr === value;
        });
        this.taskform.type = info.type;
        if (this.taskform.type === "Bool") {
          this.taskform.startValue = false;
          this.taskform.stopValue = false;
        } else if (this.taskform.type === "Float32") {
          this.taskform.startValue = "";
          this.taskform.stopValue = "";
        }
      } else {
        let value = this.taskInfo.addr;
        let info = this.resourceList.find((item) => {
          return item.addr === value;
        });
        this.taskInfo.type = info.type;
        console.log(this.taskInfo.type);
        if (this.taskInfo.type === "Bool") {
          this.taskInfo.startValue = false;
          this.taskInfo.stopValue = false;
        } else if (this.taskInfo.type === "Float32") {
          this.taskInfo.startValue = "";
          this.taskInfo.stopValue = "";
        }
      }
    },
    //获取标签页标题
    getactiveName() {
      if (this.activeName === "first") {
        this.getTask(this.deviceid);
      } else if (this.activeName === "second") {
        this.getBind(this.resourceid);
      }
    },
    arrDataBy(arr, key, where) {
      const data = [];
      for (let i = 0; i < arr.length; i++) {
        const val = arr[i][key];
        if (where && typeof where === "function") {
          const B = where(arr[i]);
          if (B) {
            data.push(val);
          }
        } else {
          data.push(val);
        }
      }
      return data;
    },
    exportExcel() {
      // /* generate workbook object from table */
      var xlsxParam = {
        raw: true,
      }; // 导出的内容只做解析，不进行格式转换
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#exportTab"),
        xlsxParam
      );

      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream",
          }),
          "资源历史数据.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") {
        }
      }
      return wbout;
    },
    async filterResourceHistory() {
      // 获取最新数据
      await this.getResourceHistory(this.resourceRow);

      const tableData = this.tableDataResourceHistory;
      const options = this.tableDataResourceHistory;

      const list = tableData.reduce((pre, cur, index) => {
        if (cur.data === "false") {
          pre.push(index);
        }
        return pre;
      }, []);

      const list2 = tableData.reduce((pre, cur, index) => {
        if (cur.data === "true") {
          pre.push(index);
        }
        return pre;
      }, []);

      let indexList = [];
      const newList = [...arrange(list), ...arrange(list2)];

      newList.forEach((item) => {
        item.shift();
        indexList.push(...item);
      });

      const resultList = options.filter((_, i) => indexList.indexOf(i) === -1);
      this.tableDataResourceHistory = resultList;
    },
    open(addr, value, data, items) {
      this.$confirm("确定修改此数据", "修改数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(async () => {
        for (var i in items) {
          if (items[i] === value) {
            value = i;
          }
        }
        const body = {
          deviceId: this.deviceid,
          regs: [
            {
              addr: addr,
              type: data,
              value: value,
            },
          ],
        };
        device
          .getDeviceWriteReg(body)
          .then((res) => {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消修改",
            });
          });
      });
    },
    changeValue(id, item) {
      this.id = id;
      this.ValueDialogVisible = true;
      this.editValueForm = {
        id: id,
        ...item,
      };
    },
    orderValue() {
      const body = {
        deviceId: this.editValueForm.id,
        regs: [
          {
            addr: this.editValueForm.addr,
            type: this.editValueForm.type,
            value: this.editValueForm.value,
          },
        ],
      };
      device.getDeviceWriteReg(body).then((res) => {
        if ((res.code = 200)) {
          this.ValueDialogVisible = false;
          this.getresourceByDevice(this.$store.state.deviceRow, this.deviceid);
        }
      });
    },
    // 格式化时间
    formatDate(value, fmt) {
      if (!value) return "";
      if (fmt) {
        if (fmt === "Y") return moment(value).format("YYYY");
        if (fmt === "M") return moment(value).format("YYYY-MM");
        if (fmt === "m") return moment(value).format("YYYY-MM-DD HH:mm");
        if (fmt === "s") return moment(value).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return moment(value).format("YYYY-MM-DD");
      }
    },
    // 资源历史记录分页
    handleSizeChangeResourceHistory(size) {
      this.resourceHistoryPageSize = size;
    },
    handleCurrentChangeResourceHistory(currentPage) {
      this.resourceHistoryCurrentPage = currentPage;
    },
  },
  destroyed() {
    let state = this.$store.state;
    state.tableDataDevice = [];
    state.newListDevice = [];
    state.tableDataResource = [];
    state.deviceid = "";
    state.groupid = "";
  },
};
</script>

<style>
.btn-active {
  background-color: #3280fc !important;
  color: #fff !important;
}

.btn-group {
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  width: 128px;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.el-card__body {
  padding-bottom: 10px !important;
}

.childDeviceConf {
  margin-bottom: 10px;
  background-color: #efefef;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.childDeviceConf > span {
  width: 140px;
}

.childDeviceConf .el-input {
  width: 170px;
}

.childDeviceConf .el-select .el-input {
  width: 170px;
}
</style>
